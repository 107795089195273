<template>
  <div style="">
    <lunbo></lunbo>
    <div class="main" style="padding: 0 10%;font-size: 14px;text-align: left;">
      <p style="
			font-size: 14px;
			text-align: left;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 28px;
      margin-top: 60px;
    	margin-bottom: 20px;
			color: #181818;
			opacity: 1;">{{ $t("aqfx.title") }}</p>
      <div style="display: flex;">
        <div class="img-div">
          <img src="images/usable.png"/>
          <p class="img-div-h2">{{ $t('aqfx.usability') }}</p>
          <p>{{ $t('aqfx.usabilityIntroduce') }}</p>
        </div>
        <div class="img-div">
          <img src="images/forecast.png"/>
          <p class="img-div-h2">{{ $t('aqfx.unpredictability') }}</p>
          <p>{{ $t('aqfx.unpredictabilityIntroduce') }}</p>
        </div>

        <div class="img-div">
          <img src="images/control.png"/>
          <p class="img-div-h2">{{ $t('aqfx.autonomy') }}</p>
          <p>{{ $t('aqfx.autonomyIntroduce') }}</p>
        </div>

        <div class="img-div">
          <img src="images/fit.png"/>
          <p class="img-div-h2">{{ $t('aqfx.consistency') }}</p>
          <p>{{ $t('aqfx.consistencyIntroduce') }}</p>
        </div>

        <div class="img-div">
          <img src="images/verifiable.png"/>
          <p class="img-div-h2">{{ $t('aqfx.verifiability') }}</p>
          <p>{{ $t('aqfx.verifiabilityIntroduce') }}</p>
        </div>

      </div>

      <p style="margin: 10px 0;margin-top:20px " :class="yuyan == 'zh'?'textindent':''">{{$t('aqfx.introduce')}}</p>
      <p style="font-size: 14px ; font-weight: bold;margin: 25px 0;"> {{ $t('aqfx.title1') }}</p>
      <p :class="yuyan == 'zh'?'textindent':''">{{$t('aqfx.introduce_a1')}}</p>
      <p style="color: red;margin: 10px 0;">{{$t('aqfx.introduce_a2')}}</p>
      <p style="font-size: 14px ; font-weight: bold;margin: 25px 0;">{{$t('aqfx.title2')}}</p>
      <p style="margin: 10px 0" :class="yuyan == 'zh'?'textindent':''">{{$t('aqfx.introduce_b1')}}</p>
      <p style="margin: 20px 0" :class="yuyan == 'zh'?'textindent':''">{{$t('aqfx.introduce_b2')}}</p>

      <img style="width:50%;display: block;text-align: center;margin: 0 auto;margin-top: 50px;"
           src="images/electricity.png"/>

      <img style="width:50%;display: block;text-align: center;margin: 0 auto;margin-bottom: 10px;"
           src="images/satellite.png"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      yuyan:localStorage.getItem('locale'),
    }
  },
}
</script>
<style scoped>
.main{
  line-height: 21px;
}
.img {
  width: 100%;
  height: 100%;
}

.img-div-h2 {
  margin: 8px 0;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.img-div {
  flex: 1;
  overflow: hidden;
  margin: 36px 5px;
  text-align: center;
}
.img-div img{
  height: 65px;
}
.textindent{
  text-indent: 2em;
}
</style>
