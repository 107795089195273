<template>
  <div>
    <lunbo></lunbo>
    <div style="background:#f3f4f6;">
      <div style="display:flex;padding:10px 20%">
        <div v-for="(item,index) in navList" :style="item.id == currentNav ? 'border-bottom:2px solid #2979ff' : ''"
             :key="index" style="margin:0 20px;width:max-content;padding:0 5px;cursor:pointer"
             @click="currentNav = item.id">
          {{ item.title }}

        </div>
      </div>

    </div>
    <div>
      <div style="padding:10px 20%">
        <div @click="toLink(item)" v-for="(item,index) in list" :key="index"
             style="cursor:pointer;border-bottom:1px solid #dedede;padding-bottom:10px;margin:10px 0 ;display:flex;text-align: left;">
          <div
              style="width:max-content;display:flex;align-items:center;line-height:22px;padding:20px 30px;padding-bottom: 0;padding-left:0;border-right:1px solid #dedede">

            <span class="small"
                  style="font-size:40px;font-weight:bold;">{{ item.d }} &nbsp; /  &nbsp; &nbsp; &nbsp; </span>&nbsp;
            &nbsp; <span style="margin-left:10px;font-size:14px;font-weight:bold">{{ item.m }}<br>{{ item.y }}</span>
          </div>
          <div style="padding-left:20px;text-algin:left;flex:1;">
            <div style="font-size: 18px;font-weight: bold;">{{ item.title }}</div>
            <p style="font-size:14px;margin-top: 10px">文章来源：{{ item.origin }}</p>
            <!--                    <p style="font-size:14px;margin-top: 18px"  v-if="item.origin_url">链接：{{item.origin_url}}</p>-->
            <p style="font-size:14px;margin-top: 10px;line-height:22px;" v-html="item.introduction"></p>
          </div>

        </div>

      </div>
      <el-pagination

          @current-change="getList"
          :current-page="page"

          :page-size="limit"
          layout="total, prev, pager, next, jumper"
          :total="count">
      </el-pagination>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [],
      currentNav: '',
      page: 1,
      limit: 10,
      count: 0,
      list: []
    }
  },
  watch: {
    currentNav() {
      this.page = 0;
      this.getList()
    }
  },
  methods: {
    toLink({id}) {
      window.open(`#/new-details?id=${id}&index=5`)
    },
    getList() {
      this.$post('/index/article/index', {type: this.currentNav, page: this.page, limit: this.limit}).then(
        (res) => {
          //  debugger
          this.$com
          res.data.data.forEach(v => {
            v['y'] = this.$com.timeFormat(parseInt(v.addtime + '000')).split("-")[0]
            v['m'] = this.$com.timeFormat(parseInt(v.addtime + '000')).split("-")[1]
            v['d'] = this.$com.timeFormat(parseInt(v.addtime + '000')).split("-")[2]
          })
          this.list = res.data.data
          this.count = res.data.total
        })
    },
    getArticleTypeList(){
      this.$post('/index/ArticleType/index',{}).then(({data})=>{
        this.navList =  data
        if(this.currentNav === this.navList[0].id){
          this.getList()
        }else{
          this.currentNav = this.navList[0].id
        }
      })
    }
  },
  mounted() {
    // 获取文章类型
    this.getArticleTypeList();

    this.$bus.$on('changeLang', (lang) => {
      this.getArticleTypeList()
    })
  },
}
</script>
<style scoped>
.img {
  width: 100%;
  height: 100%;
}

.el-pagination {
  margin-bottom: 20px;
}
</style>
