<template>
  <div class="download" style="min-height: 500px;">
    <div class="acquire">
      <span>获取与下载 </span>
      <span>- 历史下载</span>
    </div>
    <div class="time">时间范围:</div>
    <div class="selecttime">
      <el-date-picker
        v-model="startTime"
        type="datetime"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder="选择日期时间"
      >
      </el-date-picker>
      <span class="jianju">~</span>
      <el-date-picker
        v-model="endTime"
        type="datetime"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        placeholder="选择日期时间"
      >
      </el-date-picker>
      <span class="select" @click="historyList">查询</span>
    </div>
    <div
      class="downListFlex"
      v-for="(item, index) in downloadList"
      :key="index"
    >
      <div class="downloadListFlex">
        <span class="downIndex">{{ index + 1 }}</span>
        <div>
          <div class="downTitle">
            随机数生成时间：{{ item.random.add_bj_time | parseTime("{y}-{m}-{d} {h}:{i}") }}
          </div>
          <div class="downTime">下载时间：{{ item.addtime | parseTime }}</div>
        </div>
      </div>
      <div class="downloadAnddetail">
        <div @click="downloadTxt(item.random.original, 'fileName')">下载</div>
        <div @click="goDetails(item.random.pulse_index,index)">查看详情</div>
      </div>
    </div>
    <div class="paging">
      <el-pagination
        class="text_center"
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout="total,  prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      yuyan:localStorage.getItem('locale'),
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      startTime: "",
      endTime: "",
      downloadList: [],
      currentPage: 1, //初始页
      pagesize: 5,
      total: 0,
      randomId:""
    };
  },
  filters: {
    //时间戳转日期
    parseTime(time, cFormat) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            // support "1548221490638"
            time = parseInt(time);
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.historyList();
  },
  methods: {
    //查看详情
    goDetails(pulse_index,index){
      // this.$router.push("/acquire?pulse_index="+pulse_index+'&type=type');
      this.$router.push({path:'/acquire',query:{'index':'2','pulse_index':pulse_index,'type':'type'}})
      // this.$router.push("/acquire"+`index=${2}+pulse_index=${pulse_index}`)
    },
    //下载随机数
    downloadTxt(data, fileName) {
      this.yuyan = localStorage.getItem('locale')
      this.$post("/index/setting/index").then((res) => {
        if (res.data.download == 1) {
          const file = new Blob([data], { type: "text/plain" });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(file);
          a.download = fileName;
          a.click();
          let parameter = {
            random_id: this.randomId,
          };
          this.$post("/index/random/download", parameter).then((res) => {
            location. reload()
          });
        } else {
          // this.$message.error("不能下载随机数");
          if(this.yuyan == "zh"){
            //  this.$message.error('不能下载随机数');
            this.$message({
                        message: '不能下载随机数',
                        type: 'error',
                        offset:'350'
                      });
          }else{
                // this.$message.error("Can't download random number");
                this.$message({
                        message: "Can't download random number",
                        type: 'error',
                        offset:'350'
                      });
          }
        }
      });
    },
     // 分页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.historyList();
    },
    //查询
    selectList() {},
    //历史下载列表
    historyList() {
      this.yuyan = localStorage.getItem('locale')
      let userToken = this.$cookie.get("userToken");
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
        page:this.currentPage,
        limit:this.pagesize
      };
      if (userToken) {
        this.$post("/index/RandomDownload/list", data).then((res) => {
          if (!res.data) {
            // this.$message.error("暂未数据");
                if(this.yuyan == "zh"){
                  //  this.$message.error('暂无数据');
                  this.$message({
                          message: '暂无数据',
                          type: 'error',
                          offset:'350'
                        });
                }else{
                  // this.$message.error('No data available');
                  this.$message({
                          message: 'No data available',
                          type: 'error',
                          offset:'350'
                        });
                }
            return;
          }
          this.downloadList = res.data.data;
          this.total = res.data.total;
          res.data.data.forEach(item=>{
            this.randomId =item.random_id
          });
        });
      }
    },
  },
};
</script>
<style scoped>
@import "../../public/css/download.css";

</style>
