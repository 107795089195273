<template>
  <div>
    <lunbo></lunbo>

    <!--    <div class="summarize">-->
    <!--      <img src="images/banner2.png" alt="" class="bannerImg"/>-->

    <!--      <div class="summarizeTitle">-->
    <!--        <h3 class="small" style="padding-bottom: 50px;">器件无关量子随机数信标服务（试运行）</h3>-->
    <!--        声明：本网站提供的信标随机数基于量子力学非定域性原理产生，具有最好的内禀随机性，但是由于信标随机数广播公开的特性，其不能直接用于密码学保密相关应用。如果用户将信标随机数用于商业等各类活动，本网站对由此导致的一切后果概不负责-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 声明 -->
    <div class="summarizeContent">
      <borderTitle :title="$t('acquire.title')" />
      <p :class="yuyan == 'zh' ? 'textindent' : ''" class="linheight">
        {{ $t("acquire.introduction") }}
      </p>
    </div>
    <!-- 当前信标脉冲中随机数信息及下载: -->
    <div class="info">
      <div class="summarizeContent">
        <borderTitle :title="$t('acquire.handle')" />
        <div class="newestFlex">
          <p v-for="(item, index) in changeNumber" :key="index" @click="chooseNumber(index)">
            {{ $t("acquire." + item.name) }}
          </p>
        </div>
        <borderTitle :title="$t('acquire.sjs')" />
        <div class="outputValues">
          <borderTitle title="OUTPUT_VALUE(RSA)">
            <div class="value">{{ generate.output_value && generate.output_value.toUpperCase() }}</div>
          </borderTitle>
          <borderTitle title="OUTPUT_VALUE(PQC)">
            <div class="value">{{ generate.output_value_pqc && generate.output_value_pqc.toUpperCase() }}</div>
          </borderTitle>
        </div>
        <div class="choicedownload">
          <el-form ref="form" label-width="100px">
            <el-form-item :label="$t('acquire.selectServer')">
              <el-select v-model="external_id" placeholder="请选择" @change="selectOne">
                <el-option v-for="item in selectServer" :key="item.id" :label="yuyan == 'zh' ? item.name : item.e_name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('acquire.selectTime')" inline>
              <el-date-picker class="marginR" v-model="value1" type="datetime" format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" :placeholder="$t('acquire.selectTime')" @change="createRandom">
              </el-date-picker>
              <div class="primaryButton" @click="downloadTxt(generate, '随机数')">{{ $t("acquire.download") }}</div>
            </el-form-item>
            <el-form-item :label="$t('acquire.selectTime')">
              <el-date-picker v-model="timeFrame" type="datetimerange" format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm" range-separator="-" :start-placeholder="$t('acquire.startTime')"
                :end-placeholder="$t('acquire.endTime')">
              </el-date-picker>
              <div class="primaryButton" @click="createRandomBatch" :loading="downloadLoading"
                style="margin-left: 30px">{{ $t("acquire.bulkDownload") }}
              </div>
            </el-form-item>
          </el-form>
          <!--              <p @click="downloadTxt(generate, '随机数')">下载</p>-->
          <!--          <p @click="createRandom">生成</p>-->
          <!--          <p @click="goApi()">API接口调用</p>-->
          <!--          <p @click="goHIstory()">历史下载</p>-->
        </div>
        <span>{{ $t("acquire.access") }}</span>
        <div class="primaryButton" @click="goMember" style="margin-left: 30px">{{ $t("acquire.ApiInterface") }}</div>

        <expandContent :title="$t('acquire.information1')">
          <div class="content">
            <div>
              <div class="randomInfo1">
                <div @mouseover="changeDownloadIcon(true)" @mouseleave="changeDownloadIcon(false)"
                  class="primaryButton" @click="downloadCertificatefile('/certificate/rsa.zip', 'RSA')">
                  <span>{{ $t("acquire.certificatefile") }}</span>
                  <img :class="[
        'downloadSuffixIcon',
        downloadSuffixIconActive ? 'downloadSuffixIcon_active' : '',
      ]" src="../assets/images/icon_download_active.png" alt="" />
                </div>
              </div>
              <div class="randomInfo">
                <p class="row">
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.url')">
                    <span slot="reference" class="row">URL: </span>
                  </el-popover>
                </p>
                <p>{{ generate.url ? generate.url : "" }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.version')">
                    <span slot="reference" class="row">VERSION: </span>
                  </el-popover>
                </p>
                <p>{{ generate.version }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.cipherSuite')">
                    <span slot="reference" class="row">CPPHER_SUITE: </span>
                  </el-popover>
                </p>
                <p>{{ generate.cipher_suite }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.period')">
                    <span slot="reference" class="row">PERIOD: </span>
                  </el-popover>
                </p>
                <p>{{ generate.period }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.certificateId')">
                    <span slot="reference" class="row">CERTIFICATE_ID: </span>
                  </el-popover>
                </p>
                <p>{{ generate.certificate_id && generate.certificate_id.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.chainIndex')">
                    <span slot="reference" class="row">CHAIN_INDEX:</span>
                  </el-popover>
                </p>
                <p>{{ generate.chain_index }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.pulseIndex')">
                    <span slot="reference" class="row">PULSE_INDEX:</span>
                  </el-popover>
                </p>
                <p>{{ generate.pulse_index }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.timestamp')">
                    <span slot="reference" class="row">TIMESTAMP:</span>
                  </el-popover>
                </p>
                <p>{{ generate.timestamp }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.localRandomValue')">
                    <span slot="reference" class="row">LOCAL_RANDOM_VALUE:</span>
                  </el-popover>
                </p>
                <p>
                  {{
        generate.local_random_value && generate.local_random_value.toUpperCase()
      }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.externalSourceId')">
                    <span slot="reference" class="row">EXTERNAL_SOURCE_ID:</span>
                  </el-popover>
                </p>
                <p>{{ generate.external_source_id }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.externalStatusCode')">
                    <span slot="reference" class="row">EXTERNAL_STATUS_CODE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.external_status_code }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.externalValue')">
                    <span slot="reference" class="row">EXTERNAL_VALUE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.external_value }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.previous1')">
                    <span slot="reference" class="row">PREVIOUS:</span>
                  </el-popover>
                </p>
                <p>{{ generate.previous && generate.previous.toUpperCase() }}</p>
              </div>

              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.hour')">
                    <span slot="reference" class="row">HOUR:</span>
                  </el-popover>
                </p>
                <p>{{ generate.hour && generate.hour.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.day')">
                    <span slot="reference" class="row">DAY:</span>
                  </el-popover>
                </p>
                <p>{{ generate.day && generate.day.toUpperCase() }}</p>
              </div>

              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.month')">
                    <span slot="reference" class="row">MONTH:</span>
                  </el-popover>
                </p>
                <p>{{ generate.month && generate.month.toUpperCase() }}</p>
              </div>

              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.year')">
                    <span slot="reference" class="row">YEAR:</span>
                  </el-popover>
                </p>
                <p>{{ generate.year && generate.year.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.precommitmentValue')">
                    <span slot="reference" class="row">PRECOMMITMENT_VALUE:</span>
                  </el-popover>
                </p>
                <p>
                  {{
        generate.precommitment_value && generate.precommitment_value.toUpperCase()
      }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.statusCode')">
                    <span slot="reference" class="row">STATUS_CODE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.status_code }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.type')">
                    <span style="font-weight: 300" slot="reference" class="row">TYPE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.type }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.CHSH')">
                    <span style="font-weight: 300" slot="reference" class="row">CHSH:</span>
                  </el-popover>
                </p>
                <p>{{ generate.chsh }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.method')">
                    <span style="font-weight: 300" slot="reference" class="row">METHOD:</span>
                  </el-popover>
                </p>
                <p>{{ generate.method }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.signatureValue')">
                    <!--                <span slot="reference" class='row'>SIGNATURE_VALUE:</span>-->
                    <span slot="reference" class="row">SIGNATURE:</span>
                  </el-popover>
                </p>
                <p>
                  {{ generate.signature_value && generate.signature_value.toUpperCase() }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.outputValue')">
                    <span slot="reference" class="row">OUTPUT_VALUE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.output_value && generate.output_value.toUpperCase() }}</p>
              </div>
            </div>
          </div>
        </expandContent>

        <expandContent :title="$t('acquire.information2')">
          <div class="content">
            <div>
              <div class="randomInfo1">
                <div @mouseover="changeDownloadIcon(true)" @mouseleave="changeDownloadIcon(false)"
                  class="primaryButton" @click="downloadCertificatefile('/certificate/pqc.zip', 'PQC')">
                  <span>{{ $t("acquire.certificatefile") }}</span>
                  <img :class="[
        'downloadSuffixIcon',
        downloadSuffixIconActive ? 'downloadSuffixIcon_active' : '',
      ]" src="../assets/images/icon_download_active.png" alt="" />
                </div>
              </div>

              <div class="randomInfo">
                <p class="row">
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.url')">
                    <span slot="reference" class="row">URL: </span>
                  </el-popover>
                </p>
                <p>{{ generate.url ? generate.url : "" }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.version')">
                    <span slot="reference" class="row">VERSION: </span>
                  </el-popover>
                </p>
                <p>{{ generate.version }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.cipherSuite')">
                    <span slot="reference" class="row">CPPHER_SUITE: </span>
                  </el-popover>
                </p>
                <p>{{ generate.cipher_suite_pqc }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.period')">
                    <span slot="reference" class="row">PERIOD: </span>
                  </el-popover>
                </p>
                <p>{{ generate.period }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.certificateId')">
                    <span slot="reference" class="row">CERTIFICATE_ID: </span>
                  </el-popover>
                </p>
                <p>
                  {{
        generate.certificate_id_pqc && generate.certificate_id_pqc.toUpperCase()
      }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.chainIndex')">
                    <span slot="reference" class="row">CHAIN_INDEX:</span>
                  </el-popover>
                </p>
                <p>{{ generate.chain_index }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.pulseIndex')">
                    <span slot="reference" class="row">PULSE_INDEX:</span>
                  </el-popover>
                </p>
                <p>{{ generate.pulse_index }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.timestamp')">
                    <span slot="reference" class="row">TIMESTAMP:</span>
                  </el-popover>
                </p>
                <p>{{ generate.timestamp }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.localRandomValue')">
                    <span slot="reference" class="row">LOCAL_RANDOM_VALUE:</span>
                  </el-popover>
                </p>
                <p>
                  {{
        generate.local_random_value && generate.local_random_value.toUpperCase()
      }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.externalSourceId')">
                    <span slot="reference" class="row">EXTERNAL_SOURCE_ID:</span>
                  </el-popover>
                </p>
                <p>{{ generate.external_source_id }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.externalStatusCode')">
                    <span slot="reference" class="row">EXTERNAL_STATUS_CODE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.external_status_code }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.externalValue')">
                    <span slot="reference" class="row">EXTERNAL_VALUE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.external_value }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.previous1')">
                    <span slot="reference" class="row">PREVIOUS:</span>
                  </el-popover>
                </p>
                <p>{{ generate.previous_pqc && generate.previous_pqc.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.hour')">
                    <span slot="reference" class="row">HOUR:</span>
                  </el-popover>
                </p>
                <p>{{ generate.hour_pqc && generate.hour_pqc.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.day')">
                    <span slot="reference" class="row">DAY:</span>
                  </el-popover>
                </p>
                <p>{{ generate.day_pqc && generate.day_pqc.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.month')">
                    <span slot="reference" class="row">MONTH:</span>
                  </el-popover>
                </p>
                <p>{{ generate.month_pqc && generate.month_pqc.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.year')">
                    <span slot="reference" class="row">YEAR:</span>
                  </el-popover>
                </p>
                <p>{{ generate.year_pqc && generate.year_pqc.toUpperCase() }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.precommitmentValue')">
                    <span slot="reference" class="row">PRECOMMITMENT_VALUE:</span>
                  </el-popover>
                </p>
                <p>
                  {{
        generate.precommitment_value && generate.precommitment_value.toUpperCase()
      }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.statusCode')">
                    <span slot="reference" class="row">STATUS_CODE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.status_code }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.type')">
                    <span style="font-weight: 300" slot="reference" class="row">TYPE:</span>
                  </el-popover>
                </p>
                <p>{{ generate.type }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.CHSH')">
                    <span style="font-weight: 300" slot="reference" class="row">CHSH:</span>
                  </el-popover>
                </p>
                <p>{{ generate.chsh }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.method')">
                    <span style="font-weight: 300" slot="reference" class="row">METHOD:</span>
                  </el-popover>
                </p>
                <p>{{ generate.method }}</p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.signatureValuepqc')">
                    <!--                <span slot="reference" class='row'>SIGNATURE_VALUE:</span>-->
                    <span slot="reference" class="row">SIGNATURE:</span>
                  </el-popover>
                </p>
                <p v-if="generate.signature_pqc_url && yuyan == 'zh'">
                  {{ generate.signature_pqc && generate.signature_pqc.toUpperCase() }}...<a
                    :href="generate.signature_pqc_url" target="_blank"
                    style="font-size: 13px; text-decoration: none; color: rgb(15, 136, 235)">更多</a>
                </p>
                <p v-if="generate.signature_pqc_url && yuyan == 'en'">
                  {{ generate.signature_pqc && generate.signature_pqc.toUpperCase() }}...<a
                    :href="generate.signature_pqc_url" target="_blank"
                    style="font-size: 13px; text-decoration: none; color: rgb(15, 136, 235)">More</a>
                </p>
                <p v-if="!generate.signature_pqc_url">
                  {{ generate.signature_pqc && generate.signature_pqc.toUpperCase() }}
                </p>
              </div>
              <div class="randomInfo">
                <p>
                  <el-popover placement="right" width="200" trigger="hover" :content="$t('acquire.outputValue')">
                    <span slot="reference" class="row">OUTPUT_VALUE:</span>
                  </el-popover>
                </p>
                <p>
                  {{ generate.output_value_pqc && generate.output_value_pqc.toUpperCase() }}
                </p>
              </div>
            </div>
          </div>
        </expandContent>
      </div>
    </div>
  </div>
</template>
<script>
import borderTitle from '../components/borderTitle.vue';
import expandContent from '../components/expandContent.vue';

export default {
  components: {
    borderTitle,
    expandContent
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      generate: {},
      changeNumber: [
        { id: 1, name: "latest" },
        { id: 2, name: "first" },
        { id: 3, name: "previous" },
        { id: 4, name: "next" },
      ],
      pulse_index: "",
      chooseIndex: 0,
      randomId: "",
      type: "",
      timeFrame: "",
      downloadLoading: false,
      yuyan: localStorage.getItem("locale"),
      active: 0,
      selectServer: {},
      external_id: 0,
      downloadSuffixIconActive: false,
      // eng:$t('acquire.downland')
    };
  },
  watch: {
    "$i18n.locale"(newValue) {
      if (this.$i18n.locale === "zh") {
        this.yuyan = "zh";
      } else {
        this.yuyan = "en";
      }
    },
  },
  mounted(e) {
    if (!this.yuyan) {
      this.yuyan = "zh";
    }
    document.documentElement.scrollTop = 0;
    this.pulse_index = this.$route.query.pulse_index;
    this.type = this.$route.query.pulse_index;
    // 选择服务器
    var external_id = this.$route.query.external_id;
    var sign_name = this.$route.query.external;
    this.$post("/index/random/getExternal", "", [".outputValues", ".totalValus"]).then((res) => {
      if (res.data) {
        this.selectServer = res.data;
        for (let index = 0; index < res.data.length; index++) {
          if (sign_name == res.data[index].sign_name) {
            external_id = res.data[index].id;
          }
        }
        var re = /^[0-9]+.?[0-9]*$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/
        if (
          external_id == 0 ||
          external_id == "" ||
          external_id == "undefined" ||
          external_id == null ||
          !re.test(external_id)
        ) {
          this.external_id = res.data[0].id;
        } else {
          this.external_id = Number(external_id);
        }
        // if (this.type) {
        this.createRandom();
        // }
        if (this.external_id == 1) {
          this.getConfig();
        }
      }
    });

    let date = new Date();
    this.timeFrame = [
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00`,
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 01:00`,
    ];
  },
  methods: {
    changeDownloadIcon(status) {
      this.downloadSuffixIconActive = status;
    },
    // 批量下载
    createRandomBatch() {
      this.yuyan = localStorage.getItem("locale");
      this.downloadLoading = true;
      if (!this.timeFrame[0] || !this.timeFrame[1]) return;
      let data = {
        start: this.timeFrame[0],
        end: this.timeFrame[1],
      };
      data["external_id"] = this.external_id;
      this.$post("/index/random/createRandomBatch", data).then((res) => {
        if (!res.data.length) {
          this.downloadLoading = false;
          if (this.yuyan == "zh") {
            // this.$message.error('您筛选时间范围内无信标脉冲随机数数据，请重新选择时间');
            this.$message({
              message: "您筛选时间范围内无信标脉冲随机数数据，请重新选择时间",
              type: "error",
              offset: "350",
            });
          } else {
            // this.$message.error('You are filtering the random number data without beacon pulse in the time range, please select the time again');
            this.$message({
              message:
                "You are filtering the random number data without beacon pulse in the time range, please select the time again",
              type: "error",
              offset: "350",
            });
          }
        }
        console.log(888, res);
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = res.data;
        a.click();
        // const zip = new JSZip()
        // for (let i = 0; i < res.data.length; i++) {
        //   let down = res.data[i];
        //   delete down.addtime
        //   let pqc = Object.assign({},down);
        //   delete down.output_value_pqc;
        //   delete down.signature_pqc;
        //   delete pqc.signature_value;
        //   delete pqc.output_value;
        //   zip.file(`随机数${i}.txt`, "RSA：\n"+JSON.stringify(down) + "\n\n\n\nPQC：\n" + JSON.stringify(pqc))
        // }
        // zip.generateAsync({
        //   type: "blob"
        // }).then((blob) => {
        //   this.downloadLoading = false
        //   saveAs(blob, `批量随机数.zip`)
        // }, (err) => {
        //   this.downloadLoading = false
        //   console.log('导出失败', err)
        //   if(this.yuyan == "zh"){
        //     this.$message.error('导出失败');
        //   }else{
        //     this.$message.error('Export failed');
        //   }
        // })
      });
    },
    goMember() {
      this.yuyan = localStorage.getItem("locale");
      let userToken = this.$cookie.get("userToken");
      if (!userToken) {
        if (this.yuyan == "zh") {
          // this.$message.error('当前账号未登录,请登录后操作');
          this.$message({
            message: "当前账号未登录,请登录后操作",
            type: "error",
            offset: "350",
          });
        } else {
          // this.$message.error('The current account is not logged in, please log in and operate');
          this.$message({
            message: "The current account is not logged in, please log in and operate",
            type: "error",
            offset: "350",
          });
        }
        setTimeout(() => {
          this.$router.push("/login");
        }, 1000);
        return;
      }
      this.$router.push("/member");
    },
    // 下载包
    downloadCertificatefile(url, title) {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      this.yuyan = localStorage.getItem("locale");
      if (this.yuyan == "zh") {
        a.download = title + "_数字签名验签文件.zip";
      } else {
        a.download = title + "_Certificate_files.zip";
      }

      a.click();
    },
    //下载随机数
    downloadTxt(data, fileName) {
      this.yuyan = localStorage.getItem("locale");
      for (let index = 0; index < this.selectServer.length; index++) {
        if (this.external_id == this.selectServer[index].id) {
          fileName = this.selectServer[index].sign_name + "_Beacon_";
        }
      }
      data = Object.assign({}, data);
      let userToken = this.$cookie.get("userToken");
      if (userToken) {
        fileName += new Date().getTime();
        this.$post("/index/setting/index").then((res) => {
          let userToken = this.$cookie.get("userToken");
          if (!this.randomId && !userToken) {
            if (this.yuyan == "zh") {
              // this.$message.error('当前账号未登录,请登录后操作');
              this.$message({
                message: "当前账号未登录,请登录后操作",
                type: "error",
                offset: "350",
              });
            } else {
              // this.$message.error('The current account is not logged in, please log in and operate');
              this.$message({
                message:
                  "The current account is not logged in, please log in and operate",
                type: "error",
                offset: "350",
              });
            }
            this.$router.push("/login");
          } else if (!this.randomId && userToken) {
            if (this.yuyan == "zh") {
              // this.$message.error('当前无随机数,不可下载');
              this.$message({
                message: "当前无随机数,不可下载",
                type: "error",
                offset: "350",
              });
            } else {
              // this.$message.error('There is no random number at present. It cannot be downloaded');
              this.$message({
                message: "There is no random number at present. It cannot be downloaded",
                type: "error",
                offset: "350",
              });
            }
          } else {
            if (res.data.download == 1) {
              delete data.addtime;
              let pqc = Object.assign({}, data);
              delete data.output_value_pqc;
              delete data.signature_pqc;
              delete pqc.signature_value;
              delete pqc.output_value;
              const file = new Blob(
                [
                  "RSA：\n" +
                  JSON.stringify(data) +
                  "\n\n\n\nPQC：\n" +
                  JSON.stringify(pqc),
                ],
                { type: "text/plain" }
              );
              const a = document.createElement("a");
              a.target = "_blank";
              // a.href = URL.createObjectURL(file);
              console.log(data.synchro_url);
              if (!data.synchro_url) {
                return;
              }
              //data.synchro_url = data.synchro_url.slice(32);
              a.href = data.synchro_url;
              a.download = "随机数.txt";
              a.click();
              let parameter = {
                random_id: this.randomId,
              };
              parameter["external_id"] = this.external_id;
              this.$post("/index/random/download", parameter).then((res) => { });
            } else {
              if (this.yuyan == "zh") {
                //  this.$message.error('不能下载随机数');
                this.$message({
                  message: "不能下载随机数",
                  type: "error",
                  offset: "350",
                });
              } else {
                // this.$message.error("Can't download random number");
                this.$message({
                  message: "Can't download random number",
                  type: "error",
                  offset: "350",
                });
              }
            }
          }
        });
      } else {
        if (this.yuyan == "zh") {
          //  this.$message.error('当前账号未登录,请登录后操作');
          this.$message({
            message: "当前账号未登录,请登录后操作",
            type: "error",
            offset: "350",
          });
        } else {
          // this.$message.error('The current account is not logged in, please log in and operate');
          this.$message({
            message: "The current account is not logged in, please log in and operate",
            type: "error",
            offset: "350",
          });
        }

        setTimeout(() => {
          this.$router.push("/login");
        }, 1000);
      }
    },
    //选择上一个下一个
    chooseNumber(index) {
      this.yuyan = localStorage.getItem("locale");
      this.chooseIndex = index;
      let data = {};
      data["external_id"] = this.external_id;
      // if(this.pulse_index){
      //   console.log(this.pulse_index)
      //   switch (this.chooseIndex) {
      //     case "0":
      //       data["pulse_index"] = 0;
      //       break;
      //     case "1":
      //       data["pulse_index"] = 1;
      //       break;
      //     case "2":
      //       data["pulse_index"] = parseInt(this.pulse_index)-1;
      //       break;
      //     case "3":
      //       data["pulse_index"] = parseInt(this.pulse_index)+1;
      //       break;
      //   }
      // };
      if (this.chooseIndex == 1) {
        data["pulse_index"] = 1;
      } else if (this.chooseIndex == 2) {
        if (this.pulse_index > 1) {
          data["pulse_index"] = parseInt(--this.pulse_index);
        } else {
          return;
        }
      } else if (this.chooseIndex == 3) {
        data["pulse_index"] = parseInt(++this.pulse_index);
      }
      this.$post("/index/random/find", data).then((res) => {
        if (!res.data) {
          if (this.yuyan == "zh") {
            //  this.$message.error('暂无数据');
            this.$message({
              message: "暂无数据",
              type: "error",
              offset: "350",
            });
          } else {
            // this.$message.error('No data available');
            this.$message({
              message: "No data available",
              type: "error",
              offset: "350",
            });
          }
          return;
        }
        this.value1 = this.parseTime(res.data.addtime, "{y}-{m}-{d} {h}:{i}");
        this.generate = res.data;
        this.pulse_index = res.data.pulse_index;
      });
    },
    //生成随机数
    createRandom() {
      this.yuyan = localStorage.getItem("locale");
      let data = {};
      data["external_id"] = this.external_id;
      if (this.value1) {
        data["addtime"] = this.value1;
      }
      if (this.pulse_index && this.type) {
        data["pulse_index"] = this.pulse_index;
      }
      // if(this.pulse_index){
      //   data["pulse_index"] = this.pulse_index;
      // }

      // let userToken = this.$cookie.get("userToken");
      // if (userToken) {
      this.$post("/index/random/find", data).then((res) => {
        if (!res.data) {
          if (this.yuyan == "zh") {
            // this.$message.error('暂无数据');
            this.$message({
              message: "暂无数据",
              type: "error",
              offset: "350",
            });
          } else {
            // this.$message.error('No data available');
            this.$message({
              message: "No data available",
              type: "error",
              offset: "350",
            });
          }
          return;
        }
        this.value1 = this.parseTime(res.data.addtime, "{y}-{m}-{d} {h}:{i}");
        this.generate = res.data;
        this.pulse_index = res.data.pulse_index;
        this.randomId = res.data.id;
      });
      // } else {
      //   this.$message.error("当前账号未登录,请登录后操作");
      //   this.$router.push("/login");
      // }
    },
    goHIstory() {
      this.yuyan = localStorage.getItem("locale");
      if (this.$cookie.get("userToken")) {
        this.$router.push("/download");
      } else {
        if (this.yuyan == "zh") {
          //  this.$message.error('当前账号未登录,请登录后操作');
          this.$message({
            message: "当前账号未登录,请登录后操作",
            type: "error",
            offset: "350",
          });
        } else {
          // this.$message.error('The current account is not logged in, please log in and operate');
          this.$message({
            message: "The current account is not logged in, please log in and operate",
            type: "error",
            offset: "350",
          });
        }
        setTimeout(() => {
          this.$router.push("/login");
        }, 1000);
      }
    },
    //时间戳转日期
    parseTime(time, cFormat) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            // support "1548221490638"
            time = parseInt(time);
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }

        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    },
    actives(d) {
      this.active = d;
    },
    selectOne(event, item) {
      this.external_id = event;
      this.createRandom();
    },
    getConfig() {
      var yuyan = this.yuyan;
      this.$post("/index/random/getConfig", "").then((res) => {
        if (res.data.suspend == 1) {
          if (yuyan == 'zh') {
            var message = res.data.suspend_zh;
          } else {
            var message = res.data.suspend_en;
          }
          this.$message({
            message: message,
            type: "error",
            offset: "350",
          });
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.primaryButton {
  border-radius: 4px;
  background-color: #0065a8;
  color: white;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 0px 20px;
  font-size: 15px;
  width: fit-content;
  display: inline-flex;
  line-height: 40px;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@import "../main.scss";

.outputValues {
  @include flex-row-center-center;
  @include border-box-padding(0 0 20px 0);
  gap: 20px;

  .value {
    word-break: break-all;
  }
}

.bannerImg {
  width: 100%;
}

.summarize {
  position: relative;
}

.summarizeTitle {
  position: absolute;
  top: 100px;
  color: white;
  width: 100%;
  text-align: center;
}

.summarizeContent {
  /* width: 75rem; */
  margin: 0 auto;
  text-align: start;
  padding: 0 10%;
  font-weight: bold;
}

#infoTitlecolor {
  color: #181818;
  font-size: 20px;
  /* font-weight: bold; */
  margin-bottom: 1.1875rem;
}

#infoTitlecolor span {
  display: inline-block;
  /* width: 25%; */
  text-align: center;
  cursor: pointer;
  padding: 20px 0;
  margin: 0 92px;
}

#infoTitlecolor .active {
  font-weight: bold;
  color: #0f88eb;
  border-bottom: 3px solid #0f88eb;
}

/*.summarizeContent > p:nth-child(1) {*/
/*  color: #ff3e3e;*/
/*  font-weight: bold;*/
/*}*/
.summarizeContent>p:nth-child(1) {
  color: #181818;
  font-size: 20px;
  /* font-weight: bold; */
  margin: 50px 0;
}

.newestFlex {
  display: flex;
  margin: 30px 0;
}

.newestFlex>p {
  color: #0065a8;
  border: 2px solid #0065a8;
  padding: 0.3125rem 1.5625rem;
  border-radius: 4px;
  margin-right: 2.125rem;
  cursor: pointer;
}

.choicedownload {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.choicedownload>p {
  color: #0f88eb;
  border: 1px solid #0f88eb;
  padding: 0.3125rem 1.5625rem;
  border-radius: 4px;
  margin-right: 4.125rem;
  cursor: pointer;
}

.marginR {
  margin-right: 1.875rem;
}

.info {
  width: 100%;
}

.infoTitle {
  color: #181818;
  font-size: 20px;
  font-weight: bold;
}

.random {
  word-wrap: break-word;
  text-align: left;
  margin: 1.875rem 0;
}

.random p span {
  display: inline-block;
  /* margin-left: 20px; */
  color: #000;
}

.randomInfo1 {
  display: flex;
  font-size: 14px;
  margin-bottom: 1.25rem;
  cursor: pointer;
}

.randomInfo1>p:nth-child(1) {
  color: #0f88eb;
  width: 290px;
  text-align: right;
  margin-right: 1.875rem;
  display: inline-table;
}

.randomInfo {
  display: flex;
  font-size: 14px;
  margin-bottom: 1.25rem;
  cursor: pointer;
}

.randomInfo>p:nth-child(1) {
  color: #0f88eb;
  width: 200px;
  text-align: right;
  margin-right: 1.875rem;
  display: inline-table;
}

.randomInfo>p:nth-child(2) {
  width: 56.25rem;
  word-wrap: break-word;
  text-align: left;
}

.randomInfo .row {
  font-weight: 600;
}

.textindent {
  text-indent: 2em;
}

.linheight {
  line-height: 1.5em;
}

.downloadButton {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  color: #409eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
  font-size: 14px;
  margin-left: 92px;
}

.downloadButton:hover {
  background-color: #409eff;
  color: white;
}

.downloadSuffixIcon {
  height: 20px;
  width: auto;
  margin-left: 5px;
}

.downloadSuffixIcon_active {
  filter: brightness(500%);
}
</style>
