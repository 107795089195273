<template>
  <div>
    <lunbo></lunbo>

    <div class="historyContent">

      <!--  -->
      <borderTitle :title="$t('summarize.background')" />
      <div class="phase">
        <div class="textContent">
          {{ $t('summarize.backgroundContent1') }}
          {{ $t('summarize.backgroundContent2') }}
        </div>
      </div>

      <div class="phase">
        <div class="textContent">
          {{ $t('summarize.applicationsContent1') }}
          {{ $t('summarize.applicationsContent2') }}
        </div>
      </div>

      <borderTitle :title="$t('summarize.applications')" />
      <div class="phase">
        <div class="textContent">
          {{ $t('summarize.applicationsContent1') }}
          {{ $t('summarize.applicationsContent2') }}
        </div>
      </div>

      <borderTitle :title="$t('summarize.introduction')" />
      <div class="phase">
        <div class="textContent">
          {{ $t('summarize.introductionContent1') }}
          {{ $t('summarize.introductionContent2') }}
        </div>
      </div>

      <div class="textAndImage">
        <div class="textContent">{{ $t('summarize.introductionContent3') }}</div>
        <img src="images/science.png" alt="" style="width: 500px;padding-left: 50px;" />
      </div>

      <div class="phase textContent">
        {{ $t('summarize.introductionContent4') }}
      </div>
      <div class="phase textContent">
        {{ $t('summarize.introductionContent5') }}
      </div>
      <div class="phase textContent">
        {{ $t('summarize.introductionContent6') }}
      </div>

      <div class="mixedContent textContent">
        <span>{{ $t('summarize.introductionContent4') }}</span>
        <img :src="mark1" alt="">
        <span>{{ $t('summarize.introductionContent4-1') }}</span>
        <img :src="mark2" alt="">
        <span>{{ $t('summarize.introductionContent4-2') }}</span>
        <img :src="mark3" alt="">
        <span>{{ $t('summarize.introductionContent4-3') }}</span>
        <img :src="mark4" alt="">
        <span>{{ $t('summarize.introductionContent4-4') }}</span>
        <img :src="mark5" alt="">
        <span>{{ $t('summarize.introductionContent4-5') }}</span>
        <img :src="mark6" alt="">&nbsp;,
        <img :src="mark7">&nbsp;,
        <img :src="mark8">&nbsp;,
        <img :src="mark9">&nbsp;,
        <img :src="mark10">&nbsp;,
        <img :src="mark11">&nbsp;
        <span v-show="currentLanguage == 'zh'">,</span>
        <span v-show="currentLanguage == 'en'">and</span>
        <img :src="mark12">

        <span>{{ $t('summarize.introductionContent4-6') }}</span>
      </div>

      <borderTitle :title="$t('history.securityTitle')" />
      <div class="textContent">{{ $t("aqfx.title") }}</div>
      <div class="introduceContainer">
        <div class="img-div">
          <img src="images/usable.png" />
          <p class="img-div-h2">{{ $t('aqfx.usability') }}</p>
          <p>{{ $t('aqfx.usabilityIntroduce') }}</p>
        </div>
        <div class="img-div">
          <img src="images/forecast.png" />
          <p class="img-div-h2">{{ $t('aqfx.unpredictability') }}</p>
          <p>{{ $t('aqfx.unpredictabilityIntroduce') }}</p>
        </div>

        <div class="img-div">
          <img src="images/control.png" />
          <p class="img-div-h2">{{ $t('aqfx.autonomy') }}</p>
          <p>{{ $t('aqfx.autonomyIntroduce') }}</p>
        </div>

        <div class="img-div">
          <img src="images/fit.png" />
          <p class="img-div-h2">{{ $t('aqfx.consistency') }}</p>
          <p>{{ $t('aqfx.consistencyIntroduce') }}</p>
        </div>

        <div class="img-div">
          <img src="images/verifiable.png" />
          <p class="img-div-h2">{{ $t('aqfx.verifiability') }}</p>
          <p>{{ $t('aqfx.verifiabilityIntroduce') }}</p>
        </div>
      </div>

      <div class="phase">
        <div class="textContent">{{ $t('aqfx.introduce') }}</div>
      </div>

      <div class="phase">
        <div class="textContent">{{ $t('aqfx.title1') }}</div>
      </div>

      <div class="phase">
        <div class="textContent">{{ $t('aqfx.introduce_a1') }}</div>
      </div>

      <div class="phase">
        <div class="textContent important">{{ $t('aqfx.introduce_a2') }}</div>
      </div>

      <div class="phase">
        <div class="textContent">{{ $t('aqfx.title2') }}</div>
      </div>

      <div class="phase">
        <div class="textContent">{{ $t('aqfx.introduce_b1') }}</div>
      </div>

      <div class="phase">
        <div class="textContent">{{ $t('aqfx.introduce_b2') }}</div>
      </div>

      <img class="introduceImage" src="images/electricity.png" />
      <img class="introduceImage" src="images/satellite.png" />
      <!--  -->

      <borderTitle :title="$t('history.history')" />
      <div trigger="click" height="150px" class="timtsFlex">
        <div class="hidtoryFont" :style="'left:' + timeLeft + 'px'" v-for="(item, index) in times" :key="index">
          <p>{{ item.time }}</p>
          <div class="linFlex">
            <span class="line"></span>
            <span class="yuan"></span>
            <span class="line2"></span>
          </div>
          <div class="timeContent">
            <p>{{ $t('history.' + item.content) }}</p>
          </div>
        </div>
      </div>

    </div>
    <!-- 合作与交流 -->
    <div class="cooperationBg">
      <div class="historyContent">
        <borderTitle :title="$t('history.cooperation')" />

        <p class="historybottom">
          {{ $t('history.historyContent1') }}
        </p>
        <p class="historybottom">
          {{ $t('history.historyContent2') }}
        </p>
        <p class="historybottom">
          {{ $t('history.historyContent3') }}
        </p>
        <p class="historybottom">
          {{ $t('history.historyContent4') }}
        </p>
        <p class="historybottom">
          {{ $t('history.historyContent5') }}
        </p>
        <p class="historybottom">
          {{ $t('history.historyContent6') }}
        </p>
      </div>
    </div>
    <!-- 本随机数产生平台所基于以往学术成果如下： -->
    <div class="historyContent">
      <borderTitle :title="$t('history.achievements')" />

      <div v-for="(item, index) in randomNumber" :key="index">
        <p class="fontbold">
          {{ $t('history.' + item.title) }}
        </p>
        <p class="contenfont">
          {{ item.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import borderTitle from '../components/borderTitle.vue';
export default {
  components: {
    borderTitle,
  },
  data() {
    return {
      timeLeft: 0,
      mark1: require("../../public/images/jiaobiao/8.png"),
      mark2: require("../../public/images/jiaobiao/9.png"),
      mark3: require("../../public/images/jiaobiao/10.png"),
      mark4: require("../../public/images/jiaobiao/11.png"),
      mark5: require("../../public/images/jiaobiao/12.png"),
      mark6: require("../../public/images/jiaobiao/1.png"),
      mark7: require("../../public/images/jiaobiao/2.png"),
      mark8: require("../../public/images/jiaobiao/3.png"),
      mark9: require("../../public/images/jiaobiao/4.png"),
      mark10: require("../../public/images/jiaobiao/5.png"),
      mark11: require("../../public/images/jiaobiao/6.png"),
      mark12: require("../../public/images/jiaobiao/7.png"),
      bannerlunbo: [
        { id: 1, img: "images/banner1.png" },
        { id: 2, img: "images/banner1.png" },
      ],
      currentLanguage: localStorage.getItem('locale'),
      times: [
        {
          id: 1,
          time: "1935",
          content:
            "1935",
        },
        {
          id: 2,
          time: "1964",
          content:
            "1964",
        },
        {
          id: 3,
          time: "1991",
          content: "1991",
        },
        {
          id: 4,
          time: "2007",
          content:
            "2007",
        },
        {
          id: 3,
          time: "2010",
          content:
            "2010",
        },
        {
          id: 4,
          time: "2015",
          content: "2015",
        },
        {
          id: 5,
          time: "2018",
          content: "2018",
        },
        {
          id: 6,
          time: "2018",
          content: "2018a",
        },
        {
          id: 7,
          time: "2018",
          content: "2018b",
        },
      ],
      randomNumber: [
        {
          id: 1,
          title: "achievements_1",
          content: "Yang Liu, Qi Zhao, Ming-Han Li, Jian-Yu Guan, Yanbao Zhang, Bing Bai, Weijun Zhang, Wen-Zhao Liu, Cheng Wu, Xiao Yuan, Hao Li, W. J. Munro, Zhen Wang, Lixing You, Jun Zhang, Xiongfeng Ma, Jingyun Fan, Qiang Zhang & Jian-Wei Pan, Device-independent quantum random-number generation, Nature 562, 548 (2018)."
        },
        {
          id: 2,
          title: "achievements_2",
          content: "The BIG Bell Test Collaboration, Challenging local realism with human choices, Nature 557, 212 (2018)."
        },
        {
          id: 3,
          title: "achievements_3",
          content: "Ming-Han Li, Cheng Wu, Yanbao Zhang, Wen-Zhao Liu, Bing Bai, Yang Liu, Weijun Zhang, Qi Zhao, Hao Li, Zhen Wang, Lixing You, W. J. Munro, Juan Yin, Jun Zhang, Cheng-Zhi Peng, Xiongfeng Ma, Qiang Zhang, Jingyun Fan, and Jian-Wei Pan, Test of Local Realism into the Past without Detection and Locality Loopholes, Phys. Rev. Lett. 121, 080404 (2018)."
        },
        {
          id: 4,
          title: "achievements_4",
          content: "Yang Liu, Xiao Yuan, Ming-Han Li, Weijun Zhang, Qi Zhao, Jiaqiang Zhong, Yuan Cao, Yu-Huai Li, Luo-Kan Chen, Hao Li, Tianyi Peng, Yu-Ao Chen, Cheng-Zhi Peng, Sheng-Cai Shi, Zhen Wang, Lixing You, Xiongfeng Ma, Jingyun Fan, Qiang Zhang, and Jian-Wei Pan, High-Speed Device-Independent Quantum Random Number Generation without a Detection Loophole, Phys. Rev. Lett. 120, 010503 (2018)."
        },
        {
          id: 5,
          title: "achievements_5",
          content: "Ming-Han Li, Cheng Wu, Yanbao Zhang, Wen-Zhao Liu, Bing Bai, Yang Liu, Weijun Zhang, Qi Zhao, Hao Li, Zhen Wang, Lixing You, W. J. Munro, Juan Yin, Jun Zhang, Cheng-Zhi Peng, Xiongfeng Ma, Qiang Zhang, Jingyun Fan, and Jian-Wei Pan, Test of Local Realism into the Past without Detection and Locality Loopholes, Phys. Rev. Lett. 121, 080404 (2018)."
        },
        {
          id: 6,
          title: "achievements_6",
          content: "Ming-Han Li, Xingjian Zhang, Wen-Zhao Liu, Si-Ran Zhao, Bing Bai, Yang Liu, Qi Zhao, Yuxiang Peng, Jun Zhang, Yanbao Zhang, W. J. Munro, Xiongfeng Ma, Qiang Zhang, Jingyun Fan and Jian-Wei Pan, Experimental realization of device-independent quantum randomness expansion, arXiv preprint arXiv:1902.07529 (2019)."
        },
      ]
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    // 0. 获取总长度
    let timeLength = document.getElementsByClassName('hidtoryFont').length
    let num = 0;
    // 1. 获取可见区域
    let timtsFlexDom = document.getElementsByClassName('timtsFlex')[0]
    // 2. 获取当前可见区域总个数
    let showLength = parseInt(timtsFlexDom.clientWidth / 240)
    // 3. 挪动时，当前可见区域内总长度不足时，重新开始挪动
    setInterval(() => {
      if ((timeLength - (num % timeLength)) < showLength) {
        this.timeLeft = 0;
        num = 1
      } else {
        this.timeLeft = (num % timeLength) * -240
        num++;
      }
    }, 1500)
  },
};
</script>
<style scoped>
.bannerwidth {
  width: 100%;
  height: 40rem;
}

.el-carousel__container {
  height: 40rem !important;
}

.bnannerlunImg {
  width: 100%;
  height: 40rem;
}

.el-carousel__indicators--outside {
  position: absolute !important;
}

.bannerwidth {
  position: relative;
}

.bannerTilte {
  position: absolute;
  top: 29%;
  left: 16%;
  z-index: 99;
  color: white;
}

.bannerTilte>p:nth-child(1) {
  font-size: 32px;
  margin-bottom: 78px;
}

.bannerTilte>p:nth-child(2) {
  font-size: 20px;
}

.historyContent {
  /* width: 75rem; */
  margin: 0 auto;
  text-align: left;
  padding: 0 10%;
}


.historyTitle2 {
  color: #181818;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.line3 {
  width: 54%;
  height: 0.125rem;
  background: #dcdcdc;
  display: inline-block;
}

.line2 {
  width: 66%;
  height: 0.125rem;
  background: #dcdcdc;
  display: inline-block;
}

.line22 {
  width: 46%;
  height: 0.125rem;
  background: #dcdcdc;
  display: inline-block;
}

.yuan2 {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #0f88eb;
  display: inline-block;
}

.linFlex2 {
  display: flex;
  align-items: center;
}

.hidtoryFont2>p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  width: 58%;
  text-align: end;
  padding-bottom: 10px;
}

.timeContent2 {
  font-size: 14px;
  width: 91%;
  padding-top: 1.0625rem;
  margin: 0 auto;
}

.timeContent2>p {
  width: 80%;
  text-align: center;
  padding-left: 64px;
}

.timtsFlex2 {
  display: flex;
  overflow-x: auto;
}


.historyTitle {
  color: #181818;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.line {
  width: 66%;
  height: 0.125rem;
  background: #dcdcdc;
  display: inline-block;
}

.line2 {
  width: 28%;
  height: 0.125rem;
  background: #dcdcdc;
  display: inline-block;
}

.yuan {
  width: 1rem;
  height: 1rem;
  border-radius: 16px;
  background: #0f88eb;
  display: inline-block;
}

.linFlex {
  display: flex;
  align-items: center;
  width: 240px;
}

.hidtoryFont {
  max-width: 240px;
  font-weight: bold;
  position: relative;
  transition: all 0.5s;
}

.hidtoryFont>p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  width: 78%;
  text-align: end;
  padding-bottom: 10px;
  height: 20px;
}

.timeContent {
  font-size: 12px;
  width: 60%;
  padding-top: 1.0625rem;
  margin: 0 auto;

}

.timeContent>p {
  width: 80%;
  text-align: center;
  padding-left: 64px;
}


.timtsFlex {
  display: flex;
  /*padding-left: 100px;*/
  /*padding-right: 100px;*/
  overflow: hidden;
}

.cooperationBg {
  width: 100%;
}

.historybottom {
  margin-bottom: 1.25rem;
  font-weight: bold;
}

.fontbold {
  font-weight: bold;
}

.contenfont {
  margin: 1.25rem 0;
}

.el-carousel__arrow {
  z-index: 1 !important;
}

.phase {
  margin-top: 20px;
}

.textContent {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  line-height: 23px;
  text-indent: 2rem;
  flex: 1;
}

.textAndImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.mixedContent>img {
  vertical-align: middle;
}

.introduceContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 20px 0;
  gap: 20px;
}

.introduceContainer>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-size: 14px;
  font-weight: bold;
}

.important {
  color: red;
}

.introduceImage {
  margin: 0 auto;
  margin-top: 20px;
  width: 50%;
  display: block;
}
</style>