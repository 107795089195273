<template>
  <div class="login">
    <div class="loginBg">
      <img src="images/banner1.png" alt="" class="bannerbg" />
      <div class="loginposition">
        <div v-show="loginShow">
          <p class="loginfont">{{ $t("login.accountLogin") }}</p>
          <div>
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.account')"
              v-model="loginName"
              @keyup.enter="login"
            />
          </div>
          <div>
            <input
              type="password"
              class="loginInput"
              :placeholder="$t('login.password')"
              v-model="loginPassword"
              @keyup.enter="login"
            />
          </div>
          <div class="loginButton" @click="login">{{ $t("login.login") }}</div>
        </div>
        <!-- 注册 -->
        <div v-show="signShow">
          <p class="loginfont">{{ $t("login.accountRegister") }}</p>
          <div>
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.username')"
              @blur="regCheckUsername"
              v-model="regUsername"
            />
          </div>
          <div>
            <input
              type="password"
              class="loginInput"
              :placeholder="$t('login.setPassword')"
              v-model="regPassword"
            />
          </div>
          <div>
            <input
              type="password"
              class="loginInput"
              :placeholder="$t('login.setPasswordConfirm')"
              v-model="regAffirm"
            />
          </div>
          <!-- 所属机构类型 -->
          <div>
            <el-select
              style="width: 86%"
              v-model="regOrganizationType"
              :placeholder="$t('login.organization')"
            >
              <el-option
                v-for="(item, index) in organizationType"
                :key="index"
                :label="$t('login.' + item)"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select
              style="width: 86%; margin: 0.625rem 0"
              v-model="regPurpose"
              :placeholder="$t('login.usage')"
            >
              <el-option
                v-for="(item, index) in purpose"
                :key="index"
                :label="$t('login.' + item)"
                :value="index"
                placeholder="请选择所属机构类型"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.email')"
              v-model="regEmail"
            />
          </div>
          <div class="codeFlex">
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.emailCode')"
              v-model="regCode"
            />
            <button
              v-loading="sendEmailLoadingStatus"
              v-show="show"
              class="send"
              @click="sendEmail"
            >
              {{ $t("login.getEmailCode") }}
            </button>
            <button v-show="!show" class="send count">{{ count }} s</button>
          </div>
          <div class="loginButton" @click="reg">{{ $t("login.register") }}</div>
        </div>
        <!-- 忘记密码 -->
        <div v-show="passwordShow">
          <p class="loginfont">{{ $t("login.forgetPassword") }}</p>
          <div>
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.username')"
              v-model="forgetUsername"
            />
          </div>
          <div>
            <input
              type="password"
              class="loginInput"
              :placeholder="$t('login.setNewPassword')"
              v-model="forgetPassword"
            />
          </div>
          <div>
            <input
              type="password"
              class="loginInput"
              :placeholder="$t('login.setNewPasswordConfirm')"
              v-model="forgetAffirm"
            />
          </div>
          <div>
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.email')"
              v-model="forgetEmail"
            />
          </div>
          <div class="codeFlex">
            <input
              type="text"
              class="loginInput"
              :placeholder="$t('login.emailCode')"
              v-model="forgetCode"
            />
            <button
              v-loading="reportSendEmailLoadingStatus"
              v-show="show1"
              class="send"
              @click="reportSendEmail"
            >
              {{ $t("login.getEmailCode") }}
            </button>
            <button v-show="!show1" class="send count">{{ `${count1}s` }}</button>
          </div>
          <div class="loginButton" @click="sendPassword">{{ $t("login.send") }}</div>
        </div>
        <div>
          <div class="checkboxflex">
            <p @click="register" v-show="loginShow">{{ $t("login.register") }}</p>
            <p @click="changeLogin" v-show="signShow">{{ $t("login.login") }}</p>
            <p @click="retrievePassword" v-show="loginShow">
              {{ $t("login.forgetPassword") }}
            </p>
            <!-- <p>忘记密码</p> -->
            <div class="checkbocButton" v-show="loginShow">
              <label>
                <input type="checkbox" v-model="checked" />
                <span style="padding-left: 5px">{{ $t("login.readme") }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      yuyan: localStorage.getItem("locale"),
      // 所属的机构类型
      organizationType: [
        "researchInstitutes",
        "commercialEnterprise",
        "governmentOrgans",
        "personal",
      ],
      // 用途
      purpose: [
        "scientificResearch",
        "commercialApplication",
        "personalApplication",
        "other",
      ],
      signShow: false,
      loginShow: true,
      regUsername: "",
      regPassword: "",
      regAffirm: "",
      regEmail: "",
      regOrganizationType: "",
      regPurpose: "",
      loginName: "",
      loginPassword: "",
      userName: "",
      checked: false,
      regCode: "",
      passwordShow: false,
      forgetUsername: "",
      forgetPassword: "",
      forgetAffirm: "",
      forgetEmail: "",
      forgetCode: "",
      show: true,
      count: "",
      show1: true,
      count1: "",
      timer: null,
      reportSendEmailLoadingStatus: false,
      sendEmailLoadingStatus: false,
    };
  },
  watch: {
    $route: {
      handler: function (route, oldVal) {
        console.log(route);
        if (route.query.do === "login") {
          this.loginShow = true;
          this.signShow = false;
          this.passwordShow = false;
        } else {
          this.loginShow = false;
          this.signShow = true;
          this.passwordShow = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    // 鼠标移出时校验用户名是否重复
    regCheckUsername() {
      this.yuyan = localStorage.getItem("locale");
      if (!this.regUsername) return;
      this.$post("/index/login/checkRegUsername", { username: this.regUsername }).then(
        (res) => {
          if (res.data) {
            // this.$message.error('当前用户名已注册')
            if (this.yuyan == "zh") {
              this.$message({
                message: "当前用户名已注册",
                type: "error",
                offset: "350",
              });
            } else {
              this.$message({
                message: "The current user name is registered.",
                type: "error",
                offset: "350",
              });
            }
          }
        }
      );
    },
    retrievePassword() {
      this.loginShow = false;
      this.passwordShow = true;
    },
    //忘记密码时验证码
    reportSendEmail() {
      this.yuyan = localStorage.getItem("locale");

      if (this.reportSendEmailLoadingStatus) return;

      if (!this.forgetEmail) {
        if (this.yuyan == "zh") {
          this.$message({
            message: "邮箱不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Email cannot be empty.",
            type: "error",
            offset: "350",
          });
        }
        return;
      }

      this.reportSendEmailLoadingStatus = true;

      this.$post("/index/login/sendEmail", {
        email: this.forgetEmail,
        sendType: "forgetPasswordCode",
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: this.$t("login.sendEmailSuccess"),
              type: "success",
              offset: "350",
            });

            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count1 = TIME_COUNT;
              this.show1 = false;
              this.timer = setInterval(() => {
                if (this.count1 > 0 && this.count1 <= TIME_COUNT) {
                  this.count1--;
                } else {
                  this.show1 = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message({
              message: this.$t("login.sendEmailFail"),
              type: "error",
              offset: "350",
            });
          }
        })
        .finally(() => {
          this.reportSendEmailLoadingStatus = false;
        });
    },
    //注册时验证码
    sendEmail() {
      this.yuyan = localStorage.getItem("locale");

      if (this.sendEmailLoadingStatus) return;

      if (!this.regEmail) {
        if (this.yuyan == "zh") {
          this.$message({
            message: "邮箱不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Email cannot be empty.",
            type: "error",
            offset: "350",
          });
        }
        return;
      }

      this.sendEmailLoadingStatus = true;

      this.$post("/index/login/sendEmail", {
        email: this.regEmail,
        sendType: "regEmailCode",
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: this.$t("login.sendEmailSuccess"),
              type: "success",
              offset: "350",
            });
            this.getcountDown();
          } else {
            this.$message({
              message: this.$t("login.sendEmailFail"),
              type: "error",
              offset: "350",
            });
          }
        })
        .finally(() => {
          this.sendEmailLoadingStatus = false;
        });
    },
    getcountDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    register() {
      this.loginShow = false;
      this.signShow = true;
      this.$router.push({ path: this.$route.path, query: { do: "reg" } });
    },
    changeLogin() {
      this.loginShow = true;
      this.signShow = false;
      this.$router.push({ path: this.$route.path, query: { do: "login" } });
    },
    // 注册
    reg() {
      this.yuyan = localStorage.getItem("locale");
      // if(!this.canSubmit)return;
      if (this.regUsername == "") {
        // this.$message.error("用户名不可为空");
        if (this.yuyan == "zh") {
          this.$message({
            message: "用户名不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "User name cannot be empty.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (!/^[a-zA-Z0-9]{4,15}$/.test(this.regPassword)) {
        // this.$message.error("密码格式不对");
        if (this.yuyan == "zh") {
          this.$message({
            message: "密码格式不对",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Password format is incorrect.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (this.regPassword !== this.regAffirm) {
        // this.$message.error("密码不一致");
        if (this.yuyan == "zh") {
          this.$message({
            message: "密码不一致",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Passwords are inconsistent.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (
        !/^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(
          this.regEmail
        )
      ) {
        // this.$message.error("邮箱格式不对");
        if (this.yuyan == "zh") {
          this.$message({
            message: "邮箱格式不对",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Incorrect mailbox format",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (!this.regCode) {
        // this.$message.error("验证码不可为空");
        if (this.yuyan == "zh") {
          this.$message({
            message: "验证码不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Verification code cannot be empty.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (!this.regOrganizationType && this.regOrganizationType !== 0) {
        if (this.yuyan == "zh") {
          this.$message({
            message: "所属机构类型不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "The institution type cannot be blank.",
            type: "error",
            offset: "350",
          });
        }
      }

      if (!this.regPurpose && this.regPurpose !== 0) {
        if (this.yuyan == "zh") {
          this.$message({
            message: "用途不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Usage cannot be blank.",
            type: "error",
            offset: "350",
          });
        }
      }

      this.$post("/index/login/register", {
        username: this.regUsername,
        password: this.regPassword,
        repassword: this.regAffirm,
        email: this.regEmail,
        code: this.regCode,
        regOrganizationType: this.regOrganizationType,
        regPurpose: this.regPurpose,
      }).then((res) => {
        if (res.code == 1) {
          // this.$message.error(res.msg ? res.msg : "当前用户名已注册");
          this.$message({
            message: res.msg ? res.msg : "当前用户名已注册",
            type: "error",
            offset: "350",
          });

          return;
        } else {
          if (this.yuyan == "zh") {
            this.$message({
              message: "注册成功",
              type: "success",
              offset: "350",
            });
          } else {
            this.$message({
              message: "Registration succeeded.",
              type: "success",
              offset: "350",
            });
          }

          this.$router.push("/");
        }
      });
    },
    //发送忘记密码
    sendPassword() {
      this.yuyan = localStorage.getItem("locale");
      if (this.forgetUsername == "") {
        // this.$message.error("用户名不可为空");
        if (this.yuyan == "zh") {
          this.$message({
            message: "用户名不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "User name cannot be empty.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (!/^[a-zA-Z0-9]{4,15}$/.test(this.forgetPassword)) {
        // this.$message.error("密码格式不对");
        if (this.yuyan == "zh") {
          this.$message({
            message: "密码格式不对",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Password format is incorrect.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (this.forgetPassword !== this.forgetAffirm) {
        // this.$message.error("密码不一致");
        if (this.yuyan == "zh") {
          this.$message({
            message: "密码不一致",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Passwords are inconsistent.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (
        !/^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(
          this.forgetEmail
        )
      ) {
        // this.$message.error("邮箱格式不对");
        if (this.yuyan == "zh") {
          this.$message({
            message: "邮箱格式不对",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Incorrect mailbox format",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      if (!this.forgetCode) {
        // this.$message.error("验证码不可为空");
        if (this.yuyan == "zh") {
          this.$message({
            message: "验证码不可为空",
            type: "error",
            offset: "350",
          });
        } else {
          this.$message({
            message: "Verification code cannot be empty.",
            type: "error",
            offset: "350",
          });
        }

        return;
      }
      this.$post("/index/login/forgetPassword", {
        username: this.forgetUsername,
        password: this.forgetPassword,
        repassword: this.forgetAffirm,
        email: this.forgetEmail,
        code: this.forgetCode,
      }).then((res) => {
        if (res.code == 0) {
          if (this.yuyan == "zh") {
            this.$message({
              message: "找回密码成功",
              type: "success",
              offset: "350",
            });
          } else {
            this.$message({
              message: "Password recovered successfully.",
              type: "success",
              offset: "350",
            });
          }

          setTimeout(() => {
            location.reload();
          }, 500);
        } else {
          // this.$message.error("找回密码失败");
          if (this.yuyan == "zh") {
            this.$message({
              message: "找回密码失败",
              type: "error",
              offset: "350",
            });
          } else {
            this.$message({
              message: "Password retrieval failed.",
              type: "error",
              offset: "350",
            });
          }
        }
      });
    },
    //登录
    login() {
      this.yuyan = localStorage.getItem("locale");
      this.$post("/index/login/index", {
        username: this.loginName,
        password: this.loginPassword,
      }).then((res) => {
        if (res.code == 1) {
          // this.$message.error(res.msg ? res.msg : "用户名或密码错误");
          this.$message({
            message: res.msg ? res.msg : "用户名或密码错误",
            type: "error",
            offset: "350",
          });
          return;
        } else {
          if (this.yuyan == "zh") {
            this.$message({
              message: "登录成功",
              type: "success",
              offset: "350",
            });
          } else {
            this.$message({
              message: "Login success",
              type: "success",
              offset: "350",
            });
          }

          this.userName = res.data.username;
          let userInfo = {
            userName: this.loginName,
            userPassword: this.loginPassword,
          };
          sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
          this.$router.push({ path: "/header", query: { userInfo: userInfo } });
          if (this.checked) {
            this.$cookie.set("userName", userInfo.userName, {
              expires: 7,
            });
          } else {
            this.$cookie.set("userName", userInfo.userName);
          }
          console.log(this.checked);
          this.$cookie.set("userToken", res.data.token);
          this.$router.push("/");
          location.reload();
        }
      });
      // if (this.checked == true) {
      //   console.log("checked == true");
      //   let userInfo = {
      //     userName: this.loginName,
      //     userPassword: this.loginPassword,
      //   };
      //   this.$cookie.set("userInfo", userInfo);
      //   console.log(this.$cookie.get("userInfo"));
      // } else {
      //   //清空Cookie
      //   this.$cookie.remove("userInfo");
      // }
    },
  },
};
</script>
<style scoped>
.login {
  /*margin-bottom: 3.75rem;*/
}

.bannerbg {
  width: 100%;
  height: 40rem;
}

.loginBg {
  position: relative;
}

.loginposition {
  position: absolute;
  /* width: 500px; */
  /* height: 380px; */
  top: 8%;
  left: 52%;
  background: white;
  padding-bottom: 20px;
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.checkboxflex {
  display: flex;
  width: 88%;
  margin: 0 auto;
  font-size: 14px;
  color: #181818;
  justify-content: space-between;
}

.checkboxflex > p {
  cursor: pointer;
}

.loginfont {
  font-size: 24px;
  font-weight: bold;
  padding: 1rem 0;
}

.loginInput {
  border: 0;
  width: 86%;
  border-bottom: 1px solid #dcdcdc;
  margin: 0.625rem 0;
  padding: 0.8125rem 0;
  outline: 0;
}

.loginButton {
  width: 27.5rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  font-size: 20px;
  color: white;
  background: #0f88eb;
  margin-top: 8px;
  margin-bottom: 10px;
}

.checkbocButton {
  display: flex;
  align-items: center;
}

.codeFlex {
  padding: 0 7%;
  display: flex;
}

.send {
  width: 28%;
  height: 3.26rem;
  outline: none;
  border: 0;
  font-size: 14px;
  cursor: pointer;
}
</style>
