<template>
  <div class="summarizeBody">
    <lunbo></lunbo>

    <!--    <div class="summarize">-->
    <!--      <img src="images/banner2.png" alt="" class="bannerImg" />-->
    <!--      <div class="summarizeTitle">-->
    <!--        <h3 class="small" style="padding-bottom: 50px;">器件无关量子随机数信标服务（试运行）</h3>-->
    <!--        声明：本网站提供的信标随机数基于量子力学非定域性原理产生，具有最好的内禀随机性，但是由于信标随机数广播公开的特性，其不能直接用于密码学保密相关应用。如果用户将信标随机数用于商业等各类活动，本网站对由此导致的一切后果概不负责。-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 背景 -->
    <div class="introduce">
      <div class="introduceTitle">{{ $t('summarize.background') }}</div>
      <div :class="yuyan == 'zh'?'textindent':''" class="linheight">{{ $t('summarize.backgroundContent1') }}</div>
      <div :class="yuyan == 'zh'?'textindent':''" class="linheight">
        {{ $t('summarize.backgroundContent2') }}
      </div>
    </div>
    <!-- 应用 -->
    <div class="applyBg">
      <div class="introduce">
        <div class="introduceTitle">{{ $t('summarize.applications') }}</div>
        <div :class="yuyan == 'zh'?'textindent':''" class="linheight">{{ $t('summarize.applicationsContent1') }} </div>
        <div :class="yuyan == 'zh'?'textindent':''" class="linheight">{{ $t('summarize.applicationsContent2') }} </div>
      </div>
    </div>
    <!-- 器件无关量子随机数产生系统 -->
    <div class="introduce">
      <div class="introduceTitle">{{ $t('summarize.introduction') }}</div>
      <div :class="yuyan == 'zh'?'textindent':''" class="linheight">{{ $t('summarize.introductionContent1') }}</div>
      <div :class="yuyan == 'zh'?'textindent':''" class="linheight">{{ $t('summarize.introductionContent2') }}</div>
    </div>
    <!-- 图1 -->
    <div class="introduceFlex">
      <p  :class="yuyan == 'zh'?'textindent':''" class="linheight">
        {{ $t('summarize.introductionContent3') }}
      </p>
      <img src="images/science.png" alt="" style="width: 500px;padding-left: 50px;"/>
    </div>

    <!--  -->
    <div class="introduce">
      <p>
        {{$t('summarize.introductionContent5')}}
      </p>
      <p class="contentMargintop linheight" :class="yuyan == 'zh'?'textindent':''" >
        {{ $t('summarize.introductionContent6') }}
      </p>
      <p class="contentMargintop linheight" :class="yuyan == 'zh'?'textindent':''">{{ $t('summarize.introductionContent7') }} </p>
    </div>

    <!-- 图2 -->
    <div class="echarstbg">
      <div class="introduceFlex">
        <img src="images/chartImg.png" alt=""/>
        <p style="padding-left: 16px" :class="yuyan == 'zh'?'textindent':''" class="jiaobiao linheight">
          <span>{{ $t('summarize.introductionContent4') }}</span>
         <img :src="mark1" alt="">
          <span>{{ $t('summarize.introductionContent4-1') }}</span>
          <img :src="mark2" alt="">
          <span>{{ $t('summarize.introductionContent4-2') }}</span>
          <img :src="mark3" alt="">
          <span>{{ $t('summarize.introductionContent4-3') }}</span>
          <img :src="mark4" alt="">
          <span>{{ $t('summarize.introductionContent4-4') }}</span>
          <img :src="mark5" alt="">
          <span>{{ $t('summarize.introductionContent4-5') }}</span>
          <img :src="mark6" alt="">&nbsp;,
          <img :src="mark7">&nbsp;,
          <img :src="mark8">&nbsp;,
          <img :src="mark9">&nbsp;,
          <img :src="mark10">&nbsp;,
          <img :src="mark11" >&nbsp;
          <span v-show="yuyan == 'zh'">,</span>
          <span v-show="yuyan == 'en'">and</span>
          <img :src="mark12">
        
          <span>{{ $t('summarize.introductionContent4-6') }}</span>
          
        </p>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      yuyan:localStorage.getItem('locale'),
      mark1:require("../../public/images/jiaobiao/8.png"),
      mark2:require("../../public/images/jiaobiao/9.png"),
      mark3:require("../../public/images/jiaobiao/10.png"),
      mark4:require("../../public/images/jiaobiao/11.png"),
      mark5:require("../../public/images/jiaobiao/12.png"),
      mark6:require("../../public/images/jiaobiao/1.png"),
      mark7:require("../../public/images/jiaobiao/2.png"),
      mark8:require("../../public/images/jiaobiao/3.png"),
      mark9:require("../../public/images/jiaobiao/4.png"),
      mark10:require("../../public/images/jiaobiao/5.png"),
      mark11:require("../../public/images/jiaobiao/6.png"),
      mark12:require("../../public/images/jiaobiao/7.png"),
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.summarizeBody {
  font-size: 14px;
  /* line-height: 21px; */
}

.bannerImg {
  width: 100%;
}

.summarize {
  position: relative;
}

.summarizeTitle {
  position: absolute;
  top: 100px;
  color: white;
  width: 100%;
  font-size: 16px;
  text-align: center;
}

.introduce {
  /* width: 75rem; */
  margin: 0 auto;
  text-align: start;
  margin-top: 60px;
  padding: 0 10%;
}

.introduceFlex {
  display: flex;
  align-items: center;
  /* width: 75rem; */
  margin: 0 auto;
  margin-top: 60px;
  text-align: start;
  justify-content: space-between;
  padding: 0 10%;
}

.introduceFlex > p {
  width: 50rem;
}

.introduceTitle {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.introduce > div:nth-child(2),
.introduce > div:nth-child(3) {
  font-size: 14px;
  margin-bottom: 1.875rem;
}

.applyBg {
  width: 100%;
  background: #f3f3f3;
  padding: 4px 0 21px 0;
  margin: 70px 0;
}

.echarstbg {
  width: 100%;
  background: #f3f3f3;
  margin-bottom: 4.375rem;
}

.contentMargintop {
  margin: 1.875rem 0 0 0;
}
.textindent{
  text-indent: 2em;
}
.jiaobiao img{
  margin-bottom: -6px;
}
.linheight{
  line-height: 1.5em;
}
</style>
