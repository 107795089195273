<template>
    <div class="expandContentRoot">
        <div class="expandContentTitle">
            <div class="titleText" :style="{
                color: titleColor
            }">
                <span>{{ title }}</span>
                <img @click="handleExpandImageClick" class="expandImage" v-if="!contentExpanded"
                    src="../assets/images/icon_plus_blue_64.png">
                <img @click="handleExpandImageClick" class="expandImage" v-else
                    src="../assets/images/icon_minus_blue_64.png">
            </div>
        </div>
        <div v-if="contentExpanded" class="contentWrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "borderTitle",
    props: {
        title: {
            type: String,
            required: true
        },
        titleColor: {
            type: String,
            default: '#0065a8'
        },
    },
    data() {
        return {
            contentExpanded: false
        }
    },
    methods: {
        handleExpandImageClick() {
            this.contentExpanded = !this.contentExpanded;
        }
    }
}
</script>

<style lang="scss">
.expandContentRoot {
    width: 100%;
    margin: 25px 0;

    .expandContentTitle {
        width: 100%;
        font-size: 19px;
        font-weight: bold;
        box-sizing: border-box;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;
        border-left: 6px solid #0065a8;
        background-color: #f7f7f7;
        padding: 12px 16px;

        .expandImage {
            width: 35px;
            height: 35px;
            background-color: white;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            box-sizing: border-box;
            cursor: pointer;
        }
    }

    .contentWrapper {
        width: 100%;
        padding: 12px 16px;
    }
}
</style>