<template>
    <div>
        <lunbo></lunbo>

        <div style="padding:0 20%;margin-top:30px ">
            <h2>{{detail.title}}</h2>
            <p style="font-size:12px;margin:30px 0;">
              {{ $t('newsDetail.releaseTime') }}：{{detail.addtime?$com.timeFormat( parseInt(detail.addtime + '000')):''}}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{ $t('newsDetail.source') }}：<a target="_blank" :href="detail.origin_url?detail.origin_url:'javascript:;'">{{detail.origin}}</a>
            </p>
            <div v-html="detail.content" style="margin-bottom:30px"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            detail:{}
        }
    },
    watch:{

    },
    methods: {

    },
    mounted() {
        this.$post('/index/article/detail',{id:this.$route.query.id}).then(({data})=>{
            this.detail =  data
        })
    },
}
</script>
<style scoped>
 .img{
     width: 100%;
     height: 100%;
 }
</style>
